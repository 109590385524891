import React,{useEffect,useState,forwardRef} from "react"

//import loadable from '@loadable/component'
import Layout from "../components/Layout"
import { Container, Paper, LinearProgress } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

import MaterialTable from "material-table"
import AddBox from "@material-ui/icons/AddBox"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import Edit from "@material-ui/icons/Edit"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Remove from "@material-ui/icons/Remove"
import SaveAlt from "@material-ui/icons/SaveAlt"
import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"

import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "74px",
    overflowX: "auto",
    
  },
  table: {
    maxWidth: 400,
    margin:'auto',
  },
}))

export default function Inscriptos(props) {
  const classes = useStyles()
  const [filas,setFilas]=useState({})
  useEffect(() => {
   fetch('https://spreadsheets.google.com/feeds/list/13kFNOcbqVt5DMgFuH1Y_N89geyWs_BIw4LgJ8ZJ_02o/od6/public/basic?alt=json')
   .then(res=>res.json())
   .then(json=>
    {
   
      let filas = json.feed.entry.slice(1).map((value,index)=>{
        let nombre = value.title['$t']
        let apellido = value.content['$t'].split(":")[1]
        return {nombre:nombre,apellido:apellido}

      })
     
     setFilas(filas)
    })
    
  }, [])
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };
  
  return (
    <Layout>
      <Container className={classes.root} maxWidth="md">
        <Paper style={{ padding: 20, background: "#f7b032" }}>
          {_.isEmpty(filas) ? (
            <LinearProgress color="primary" />
          ) : (
            <MaterialTable
              icons={tableIcons}
              actions={[]}
              options={{
                search: true,
                headerStyle: {
                  backgroundColor: "#39A6A2",
                  color: "white",
                  fontWeight:'bold',
                  width:100,
                },
                cellStyle:{
                  width:100,
                },
              
                padding:'dense',
                sorting: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20, 50, 100],
              }}
              columns={[
                { title: "Nombre", field: "nombre" },
                {
                  title: "Apellido",
                  field: "apellido",
                  defaultSort:'asc',
                  customSort: (a, b) => a.apellido.toLowerCase().localeCompare(b.apellido.toLowerCase()),
                },
              ]}
              data={filas}
              title="Lista de Inscriptos"
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin resultados...",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  labelRowsSelect: "Filas",
                  labelDisplayedRows: " {from}-{to} filas {count}",
                  firstTooltip: "Primera página",
                  previousTooltip: "página anterior",
                  nextTooltip: "página siguiente",
                  lastTooltip: "Última página",
                },
              }}
            />
          )}
        </Paper>
      </Container>
    </Layout>
  )
}
